@include media-breakpoint-down('sm') {
  .row {
    display: block !important;
  }
}

.group-black-image-right {
  background-color: black;

  .content-col {
    padding-top: 120px;
    padding-bottom: 120px;

    @include media-breakpoint-down(sm) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .row {
      display: block;
    }

    .container {
      @include media-breakpoint-up('sm') {
        width: ($col-sm * 12) + ($grid-gutter-width * 6);
        margin-left: auto;
        margin-right: auto;
      }

      @include media-breakpoint-up('md') {
        width: ($col-md * 6) + ($grid-gutter-width * 3);
        margin-left: auto;
        margin-right: 0;
      }

      @include media-breakpoint-up('lg') {
        width: ($col-lg * 6) + ($grid-gutter-width * 3);
        margin-left: auto;
        margin-right: 0;
      }

      .element {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }

      .Article {
        h2 {
          color: white;
          font-size: $h1-font-size;
          text-transform: uppercase;
          font-weight: 300;

          @include media-breakpoint-down(sm) {
            font-size: $h3-font-size;
          }

          strong {
            font-weight: 600;
          }
        }

        p {
          color: white;
          font-size: $font-size-lg * 0.9;
          font-weight: 300;
          line-height: $line-height-lg * 1.1;

        }

        ul {
          padding: 0;

          li {
            color: white;
            font-size: $font-size-lg * 0.9;
            font-weight: 300;
            line-height: $line-height-lg * 1.1;
            position: relative;
            list-style-type: none;
            padding-left: 15px;

            &:before {
              content: "\f105";
              font-family: "Font Awesome 5 Free";
              position: absolute;
              left: 0;
              color: $primary;
            }
          }
        }
      }
    }
  }

  .image-col {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;

    @include media-breakpoint-down('sm') {
      min-height: 400px;
      background-position: center center;
    }
  }
}


.group-black-image-left {
  background-color: black;

  .content-col {
    padding-top: 120px;
    padding-bottom: 120px;

    .container {
      @include media-breakpoint-up('sm') {
        width: ($col-sm * 12) + ($grid-gutter-width * 6);
        margin-left: auto;
        margin-right: auto;
      }

      @include media-breakpoint-up('md') {
        width: ($col-md * 6) + ($grid-gutter-width * 3);
        margin-left: 0;
        margin-right: auto;
      }

      @include media-breakpoint-up('lg') {
        width: ($col-lg * 6) + ($grid-gutter-width * 3);
        margin-left: 0;
        margin-right: auto;
      }

      .element {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }

      .Article {
        h2 {
          color: white;
          font-size: $h1-font-size;
          text-transform: uppercase;
          font-weight: 300;

          @include media-breakpoint-down(sm) {
            font-size: $h3-font-size;
          }

          strong {
            font-weight: 600;
          }
        }

        p {
          color: white;
          font-size: $font-size-lg * 0.9;
          font-weight: 300;
          line-height: $line-height-lg * 1.1;

        }

        ul {
          padding: 0;

          li {
            color: white;
            font-size: $font-size-lg * 0.9;
            font-weight: 300;
            line-height: $line-height-lg * 1.1;
            position: relative;
            list-style-type: none;
            padding-left: 15px;

            &:before {
              content: "\f105";
              font-family: "Font Awesome 5 Free";
              position: absolute;
              left: 0;
              color: $primary;
            }
          }
        }
      }
    }
  }

  .image-col {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;

    @include media-breakpoint-down('sm') {
      min-height: 400px;
      background-position: center center;
    }
  }
}

.group-fullsize-bg-black {
  .bg {
    background-color: black;
    padding-top: 120px;
    padding-bottom: 120px;
    background-size: cover;

    .Article {
      h2 {
        color: white;
        font-size: $h1-font-size;
        text-transform: uppercase;
        font-weight: 300;

        @include media-breakpoint-down(sm) {
          font-size: $h3-font-size;
        }

        strong {
          font-weight: 600;
        }
      }
    }
  }
}

.group-black {
  background-color: black;
}

.group-full-width {

  .col {
    background-size: cover;

    @include media-breakpoint-down('sm') {
      min-height: 400px;
      background-position: center center;
    }
  }

  .inner-group {
    padding-top: 120px;
    padding-bottom: 120px;

    .Article {
      h3 {
        color: white;
        font-size: $h1-font-size;
        text-transform: uppercase;
        font-weight: 300;

        @include media-breakpoint-down(sm) {
          font-size: $h3-font-size;
        }

        strong {
          font-weight: 600;
        }
      }

      p {
        font-weight: 300;
        color: $text-color-negativ;

        strong {
          font-weight: 600;
          color: $primary;
        }
      }
    }
  }
}

.group-orange {
  background-color: $primary;
  &.wrapper {
    .group-orange {
      padding-top: 120px;
      padding-bottom: 120px;

      @include media-breakpoint-down(sm) {
        padding-top: 40px;
        padding-bottom: 40px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 600;

        @include media-breakpoint-down(md) {
          font-size: $h4-font-size;
        }

        &:after {
          width: 140px;
          height: 6px;
          display: block;
          background-color: white;
          content:"";
          margin-top: 14px;
          margin-bottom: 20px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      p {
        font-size: $font-size-lg;
        font-weight: 600;
      }

      .Group {
        margin-top: 40px;

        & + .Article {
          margin-top: 30px;
        }

        .row {
          & + .row {
            margin-top: 30px;

            @include media-breakpoint-down(sm) {
              margin-top: 0;
            }
          }
        }

        .Article {
          background-color: rgba(0,0,0,0.1);
          padding: 20px;
          min-height: 100%;

          @include media-breakpoint-down(sm) {
            margin-bottom: 5px;
          }

          h4 {
            font-weight: 600;
            color: white;
            font-size: $font-size-lg;
          }

          p {
            font-size: $font-size-base;
            font-weight: 300;

            strong {
              font-weight: 700;
            }

            .price-content {
              color: white;
              text-shadow: 1px 1px 0px black;
            }
          }

          ul {
            margin: 0;
            padding: 0;

            li {
              padding: 0;
              list-style-type: none;
              background-color: white;
              border-radius: 3px;
              margin-top: 5px;
              margin-bottom: 5px;
              padding-top: 5px;
              padding-bottom: 5px;
              padding-right: 10px;
              padding-left: 40px;
              text-align: left;
              position: relative;

              &:before {
                content:"\f058";
                position: absolute;
                left: 10px;
                font-family: "Font Awesome 5 Free";
                color: $primary;
                font-size: $font-size-lg;
              }
            }
          }
        }
      }
    }
  }
}

.group-keyfacts {
 &.wrapper {
   .group-keyfacts {
     padding-top: 40px;
     padding-bottom: 40px;

     .Article {
       background-color: rgba(0,0,0,0.05);
       padding: 20px 40px;
       text-align: center;

       @include media-breakpoint-down(sm) {
         padding: 10px 20px;
         margin-bottom: 10px;
       }

       p {
         font-size: $font-size-lg;
         font-weight: 300;

         strong {
           color: $primary;
           font-weight: 700;
           font-size: $h1-font-size;

           @include media-breakpoint-down(sm) {
             font-size: $h3-font-size;
           }
         }
       }
     }
   }
 }

  & + .intro {
    padding-top: 0;
  }
}

.Group {
  &.group-map-homepage {
    .row {
      margin: 0 !important;
    }
    .col-md-6, .col-sm-6, .col-lg-6 {
      padding: 0 !important;

      .map-canvas {
        @include media-breakpoint-down(md) {
          height: 300px !important;
        }
      }

      &:nth-of-type(1) {
        position: relative;

        &:after {
          position: absolute;
          right: 10px;
          top: 10px;
          content:"Standorte National";
          padding: 5px 10px;
          background-color: white;
          color: black;
          z-index: 1000;
          -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
          box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
        }
      }

      &:nth-of-type(2) {
        position: relative;

        &:after {
          position: absolute;
          right: 10px;
          top: 10px;
          content:"Standorte International";
          padding: 5px;
          background-color: white;
          color: black;
          z-index: 1000;
          -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
          box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
        }
      }
    }
  }
}