.header-subpage {
  .bg {
    padding-top: 200px;
    padding-bottom: 100px;
    background-size: cover;
    background-position: center;

    h1 {
      background-color: $primary;
      padding: 5px 10px;
      font-weight: bold;
      color: white;
      display: inline-block;

      @include media-breakpoint-down(md) {
        font-size: $h3-font-size !important;
      }
    }
  }
}

.intro {
  padding-top: 50px;
  padding-bottom: 50px;

  @include media-breakpoint-down(sm) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  p {
    font-size: $font-size-lg * 0.9;
    font-weight: 300;
    line-height: $line-height-lg * 1.1;

    strong {
      font-weight: 600;
    }
  }

  ul {
    padding: 0;

    li {
      font-size: $font-size-lg * 0.9;
      font-weight: 300;
      line-height: $line-height-lg * 1.1;
      position: relative;
      list-style-type: none;
      padding-left: 15px;

      &:before {
        content: "\f105";
        font-family: "Font Awesome 5 Free";
        position: absolute;
        left: 0;
        color: $primary;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    font-weight: 300;

    strong {
      font-weight: 600;
      color: $primary;
    }

    &:after {
      width: 140px;
      height: 6px;
      display: block;
      background-color: $primary;
      content:"";
      margin-top: 14px;
      margin-bottom: 20px;
    }
  }
}

.article-center {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;

  p {
    font-size: $font-size-lg * 0.9;
    font-weight: 300;
    line-height: $line-height-lg * 1.1;

    strong {
      font-weight: 600;
    }
  }

  h2 {
    text-transform: uppercase;
    font-weight: 300;

    strong {
      font-weight: 600;
      color: $primary;
    }

    &:after {
      width: 140px;
      height: 6px;
      display: block;
      background-color: $primary;
      content:"";
      margin-top: 14px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.logos {
  margin-bottom: 100px;
}

.interferer {
  background-color: $primary;
  padding: 20px;
  color: black;
  margin-top: 20px;

  h3 {
    font-size: $h5-font-size;
    font-weight: bold;
  }

  p {
    font-size: $font-size-base !important;

    strong {
      color: black;
      font-weight: 600;
    }

    a {
      color: white;
    }
  }
}

.inteferer-full-col {
  &.wrapper {
    background-color: $primary;
    padding: 20px;
    color: black;
    text-align: center;

    p {
      &:first-of-type {
        font-size: $h3-font-size !important;
        font-weight: 600;

        @include media-breakpoint-down(sm) {
          font-size: $font-size-lg !important;
        }
      }

      a {
        color: white;
      }

      .button {
        &.button-default {
          a {
            color: black;
          }
        }
      }
    }
  }
}

.inteferer-full-col-white {
  &.wrapper {
    padding: 20px;
    color: black;
    text-align: center;

    p {
      &:first-of-type {
        font-size: $h3-font-size !important;
        font-weight: 600;
      }
    }
  }
}

.hashtag {
  color: $primary;
  font-weight: bold;
}

.tuning-services {
  margin-bottom: 30px;

  .bg {
    background-size: cover;

    .overlay {
      padding-top: 70px;
      padding-bottom: 70px;
      background-color: rgba(0,0,0,0.7);
      text-align: center;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer;

      p {
        a {
          color: white;

          &:before {
            color: $primary;
            font-family: "Font Awesome 5 Free";
            content:"\f101";
            display: block;
            font-size: $h2-font-size;
          }

          &:hover {
            text-decoration: none;

            &:before {
              color: white;
            }
          }
        }
      }
    }
  }

  &:hover {
    .bg {
      .overlay {
        background-color: $primary;

        p {
          a {
            &:before {
              color: white;
            }
          }
        }
      }
    }
  }
}

.group-margin {
  .group-margin {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}