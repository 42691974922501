$col-sm: map-get($container-max-widths, 'sm') / $grid-columns;
$col-md: map-get($container-max-widths, 'md') / $grid-columns;
$col-lg: map-get($container-max-widths, 'lg') / $grid-columns;
$col-xl: map-get($container-max-widths, 'xl') / $grid-columns;


.container-grouped {
  @include media-breakpoint-up('md') {
    .row {
      display: flex;
    }
  }

  padding-left:  floor(($grid-gutter-width / 2));
  padding-right: ceil(($grid-gutter-width / 2));
  @include clearfix;

  @include media-breakpoint-up('md') {
    $list: ".col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xl-1";

    @for $i from (1 + 1) through $grid-columns {
      $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";
    }

    #{$list} {
      background-size: cover;
      padding: 0;
      &:first-child {
        .container {
          float: right;
        }
      }

      &:last-child {
        .container {
          float: left;
        }
      }
    }

    @for $i from 1 through $grid-columns {
      .col-md-#{$i} {
        @include media-breakpoint-up('md') {
          &, .container {
            width: #{$col-md * $i};
          }

          &:first-child,
          &:last-child {
            width: calc(50% - #{$col-md * (6 - $i)});

            .container {
              width: #{$col-md $i};
            }
          }
        }
        @include media-breakpoint-up('lg') {
          &, .container {
            width: #{$col-lg * $i};
          }

          &:first-child,
          &:last-child {
            width: calc(50% - #{$col-lg * (6 - $i)});

            .container {
              width: #{$col-lg * $i};
            }
          }
        }
        @include media-breakpoint-up('xl') {
          &, .container {
            width: #{$col-xl * $i};
          }

          &:first-child,
          &:last-child {
            width: calc(50% - #{$col-xl * (6 - $i)});

            .container {
              width: #{$col-xl * $i};
            }
          }
        }
      }
    }
  }
}