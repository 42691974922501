.navbar-brand {
  img {
    width: 180px;
    height: auto;
  }
}

#top {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
}

.navbar-toggle-button {
  width: 60px;
  height: 50px;
  display: flex;
  border: 0;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  background-color: rgba(0,0,0,0.4);
  padding: 10px;
  box-sizing: border-box;

  .navbar-toggle-bar {
    width: 40px;
    height: 4px;
    background-color: white;
    content:"";
    display: block;

    &:nth-of-type(2) {
      width: 20px;
    }

    &:nth-of-type(3) {
      width: 30px;
    }
  }

  &:hover {
    .navbar-toggle-bar {
      background-color: $primary;
    }
  }
}

#main-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  z-index: 4000;
  display: none;

  .close-nav-wrapper {
    height: 120px;
  }

  #close-nav {
    display: block;
    position: absolute;
    right: 45px;
    top: 25px;

    .fa {
      color: white;
      font-size: $h1-font-size * 1.2;
      curspor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }

  .dropdown-navigation {
    padding-right: 20px;

    li.nav-item {
      display: block;
      list-style-type: none;
      background-color: transparent;
      margin: 0;
      text-align: right;

      a.nav-link {
        display: inline-block;
        font-size: $h1-font-size;
        text-transform: uppercase;
        font-weight: 700;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;

        @include media-breakpoint-down(sm) {
          font-size: $h4-font-size;
        }

        &:hover {
          background-color: $primary;
          color: white;
          letter-spacing: 1px;
        }
      }
    }
  }
}