.Gallery {
  padding-top: 120px;

  h2 {
    color: black;
    font-size: $h1-font-size;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 30px;
    text-align: center;

    &:after {
      width: 140px;
      height: 6px;
      display: block;
      background-color: $primary;
      content:"";
      margin-top: 14px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .thumbnails {
    padding-left: 30px;
    padding-right: 30px;
    .img {
      margin-bottom: 30px;
      overflow: hidden;
      width: 100%;
      height: auto;

      img {
        -webkit-transition: transform 0.2s ease;
        -moz-transition: transform 0.2s ease;
        -o-transition: transform 0.2s ease;
        transition: transform 0.2s ease;
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
}

.lum-lightbox-position-helper {
  padding: 30px;
}