.SeoLinkList {
  a {
    padding: 0;
    font-weight: 300;
    color: white;
    line-height: $font-size-base * 1.8;

    &:hover {
      color: $primary;
    }
  }
}

.manufacturer-engine-list {
  padding-left: 0;

  li {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;

    a {
      background-color: $dark-grey;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      display: block;

      span.manufacturer-small {
        display: block;
        font-size: $font-size-sm;
      }

      &:hover {
        background-color: $primary;
        color: white;
        text-decoration: none;
      }
    }
  }
}

.button {
  a {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.link-list-subpage {
  padding-top: 30px;
  padding-bottom: 30px;
}