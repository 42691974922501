.button {
  a, button {
    @extend .btn;
    border-radius: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;

    &:before {
      content: "\f105";
      color: black;
      display: inline-block;
      font-family: "Font Awesome 5 Free";
      margin-right: 6px;
    }
  }

  &.button-primary {
    a {
      @extend .btn-primary;
      color: white;
    }
  }

  &.button-cart {
    button {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: $font-size-base * 0.85;

      @include media-breakpoint-down('md') {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &:before {
        display: none;
      }
    }
  }

  &.button-default {
    a, button {
      background-color: white;
      color: black;

      &:hover {
        background-color: $text-color-negativ;
      }
    }
  }

  &.button-default-on-white {
    a {
      background-color: $text-color-negativ;
      color: black;

      &:hover {
        background-color: darken($text-color-negativ, 10%);
      }
    }
  }

  &.button-primary-phone {
    a {
      @extend .btn-primary;
      color: white;

      &:before {
        content: "\f095";
        color: black;
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        margin-right: 6px;
      }
    }
  }
}


.delete {
  .btn {
    border-radius: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: $text-color-negativ;
    color: black;

    &:hover {
      background-color: darken($text-color-negativ, 10%);
    }
  }
}

.float-button-date {
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 5000;

  &.button {
    &.button-primary {
      a {
        font-size: $font-size-sm;
        color: black;
        text-transform: none;
        border-radius: 30px;
        letter-spacing: 1px;
        box-shadow: -1px 3px 19px 0px rgba(0,0,0,0.23);
        -webkit-box-shadow: -1px 3px 19px 0px rgba(0,0,0,0.23);
        -moz-box-shadow: -1px 3px 19px 0px rgba(0,0,0,0.23);

        @include media-breakpoint-down(md) {
          font-size: $font-size-sm * 0.9;
        }

        &:before {
          content:"\f073";
        }
      }
    }
  }
}

.button-social {
  a {
    @extend .btn;
    border: 0;
    border-radius: 0;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 10px;
    background-color: $primary;
    color: black;

    .fab, .fas {
      margin-right: 8px;
      display: inline-block;
    }

    &:hover {
      color: black !important;
    }
  }

  &.button-block {
    a {
      @extend .btn-block;
    }
  }

  &.button-whatsapp {
    a {
      background-color: $color-whatsapp;
      color: white;
    }
  }

  &.button-facebook {
    a {
      background-color: $color-facebook;
      color: white;
    }
  }

  &.button-twitter {
    a {
      background-color: $color-twitter;
      color: white;
    }
  }
}

.button-contact-form {
  margin-top: 15px;
  display: block;
}