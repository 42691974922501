p {
  font-size: $font-size-lg * 0.9;
}

ul {
  li {
    font-size: $font-size-lg * 0.9;
  }
}

.article-icon-left {
  margin-top: 40px;
  padding-left: 80px;
  position: relative;

  .img {
    position: absolute;
    left: 0;
    width: 60px;

    img {
      height: auto;
    }
  }

  h3 {
    font-size: $h5-font-size;
    color: white;
    font-weight: 300;

    strong {
      font-weight: 600;
    }
  }

  p {
    font-weight: 300;
    color: $text-color-negativ;
  }

  ul {
    padding-left: 0;

    li {
      list-style-type: none;
      padding-left: 15px;
      position: relative;
      color: $text-color-negativ;
      font-weight: 300;

      &:before {
        content: "\f105";
        font-family: "Font Awesome 5 Free";
        position: absolute;
        left: 0;
        color: $primary;
      }
    }
  }
}

.article-quote {
  &.wrapper {
    background-color: $primary;
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: center;

    blockquote {
      font-size: $h3-font-size;
      font-weight: 400;
      color: black;

      @include media-breakpoint-down(sm) {
        font-size: $h4-font-size;
      }

      &:before {
        content: "\f10e";
        font-family: "Font Awesome 5 Free";
        display: block;
        font-size: $h1-font-size;
        color: white;
      }
    }
  }
}

.article-margin-top {
  margin-top: 50px;
}

.price-content {
  font-size: $font-size-lg * 1.4;
  font-weight: 700;
  text-shadow: 1px 1px 0px $primary;
}

.list-cols {
  ul {
    column-count: 3;
    column-gap: 30px;
    margin-top: 30px;
    padding-left: 0;
    -moz-column-count: 2;
    break-inside: avoid;

    li {
      list-style-type: none;
      position: relative;
      box-sizing: border-box;
      background-color: white;
      padding: 10px;
      padding-left: 40px;
      margin-bottom: 15px;
      font-size: $font-size-lg;
      border-radius: 5px;
      -webkit-box-shadow: 0px 2px 5px 3px rgba(0,0,0,0.05);
      box-shadow: 0px 2px 5px 3px rgba(0,0,0,0.05);
      break-inside: avoid;
      transform: translateZ(0);

      &:before {
        content:"\f058";
        position: absolute;
        left: 10px;
        font-family: "Font Awesome 5 Free";
        color: $primary;
        font-size: $font-size-lg;
      }
    }
  }
}

.text-small {
  font-size: $font-size-sm;
  font-weight: 300;

  p {
    font-size: $font-size-sm;
    font-weight: 300;
  }
}

.get-date {
  padding-top: 20px;
  padding-bottom: 40px;

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: $h4-font-size;

    &:after {
      width: 140px;
      height: 6px;
      display: block;
      background-color: $primary;
      content:"";
      margin-top: 14px;
      margin-bottom: 20px;
    }
  }
}