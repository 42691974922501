footer {
  background-color: black;
  padding-top: 120px;
  padding-bottom: 120px;

  h3, .h3 {
    font-size: $font-size-lg;
    font-weight: 500;
    color: white;
    padding-left: 30px;
    position: relative;
    text-shadow: 1px 1px 1px black;
    margin-bottom: 30px;

    @include media-breakpoint-down('sm') {
      margin-top: 20px;
    }

    &:before {
      content:"";
      background-image: url("../img/streifen.png");
      background-size: contain;
      width: 25px;
      height: 20px;
      position: absolute;
      left: 0;
      background-repeat: no-repeat;
      top: 4px;
    }
  }

  ul {
    margin: 0;
    padding-left: 0;

    li {
      list-style-type: none;
      padding: 0;
      margin: 0;

      a.nav-link {
        padding: 0;
        font-weight: 300;
        color: white;
        line-height: $font-size-base * 1.8;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .social-media {
    margin-top: 22px;

    a {
      color: white;
      font-size: $h3-font-size;
      margin-right: 15px;

      &:hover {
        color: $primary;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .logo {
    max-width: 180px;
    height: auto;
  }

  .SeoLinkList {
    ul {
      column-count: 4;
      column-gap: 15px;

      @include media-breakpoint-down('md') {
        column-count: 2;
      }

      @include media-breakpoint-down('sm') {
        column-count: 1;
      }
    }
  }

  .footer-nav {
    column-count: 3;
    column-gap: 15px;

    @include media-breakpoint-down('md') {
      column-count: 1;
    }
  }

  .row {
    & + .row {
      padding-top: 80px;
    }
  }
}