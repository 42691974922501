.form-contact {
  &.wrapper {
    background-color: black;
    padding-top: 60px;
    padding-bottom: 60px;

    label {
      color: white;
    }

    .form-control {
      display: block;
      width: 100%;
      background-color: #222222;
      border-radius: 0;
      box-shadow: none;
      border: 0;
      color: white;
      padding: 15px 20px;
    }

    .form-text {
      p {
        font-size: $font-size-sm * 0.9;
        color: white;
      }
    }
  }
}