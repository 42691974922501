.header-article {
  .bg {
    height: 90vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      height: 70vh;
    }

    h1 {
      color: white;
      text-transform: uppercase;
      font-weight: 300;
      font-size: $h1-font-size * 1.4;
      text-shadow: 1px 1px 1px black;

      @include media-breakpoint-down(sm) {
        font-size: $h2-font-size;
      }

      strong {
        font-weight: 600;
      }
    }

    p {
      font-size: $font-size-lg;
      font-weight: 500;
      color: white;
      padding-left: 30px;
      position: relative;
      text-shadow: 1px 1px 1px black;

      &:before {
        content:"";
        background-image: url("../img/streifen.png");
        background-size: contain;
        width: 25px;
        height: 20px;
        position: absolute;
        left: 0;
        background-repeat: no-repeat;
        top: 4px;
      }
    }
  }
}

.social-media-address {
  background-color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $dark-grey;

  .social-media-col {
    a {
      color: white;
      font-size: $font-size-lg;
      margin-right: 10px;

      &:hover {
        color: $primary;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .address-col {
    p {
      display: inline-block;
      color: $text-color-negativ;
      text-transform: uppercase;
      font-weight: 300;
      font-size: $font-size-sm;
      margin-bottom: 0;
      margin-right: 15px;

      a {
        color: $text-color-negativ;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .address-desktop {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .address-mobile {
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }
}