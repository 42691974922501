.cart-side {
  .cart-navigation {
    position: fixed;
    width: 80px;
    height: 60px;
    background-color: $primary;
    right: 0;
    top: calc(50% - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    a {
      color: black;
      font-size: $font-size-lg;

      .badge {
        background-color: white;
        font-size: $font-size-sm;
        margin-left: 5px;
        font-weight: 400;
      }
    }

    @include media-breakpoint-down(md) {
      left: 15px;
      bottom: 15px;
      right: auto;
      top: auto;
      width: 60px;
      height: 50px;

      a {
        @include media-breakpoint-down(md) {
          font-size: $font-size-sm * 0.9;
        }

        .badge {
          @include media-breakpoint-down(md) {
            font-size: $font-size-sm * 0.8;
          }
        }
      }
    }

    &:hover {
      transform: scale(1.05);
      background-color: darken($primary, 10%);
    }
  }
}

.cart-page {
  padding-top: 50px;
  padding-bottom: 50px;

  .amount {
    input.form-control {
      text-align: right;
      padding: 0;
      background-color: transparent;
      border: 0;
    }
  }
}

.Checkout {
  &.wrapper {
    .Checkout {
      padding-bottom: 50px;

      h1 {
        text-transform: uppercase;
        font-weight: 300;
        margin-top: 50px;

        &:after {
          width: 140px;
          height: 6px;
          display: block;
          background-color: $primary;
          content:"";
          margin-top: 14px;
          margin-bottom: 20px;
        }
      }

      h3 {
        text-transform: uppercase;
        font-weight: 300;
        margin-top: 50px;

        &:after {
          width: 140px;
          height: 6px;
          display: block;
          background-color: $primary;
          content:"";
          margin-top: 14px;
          margin-bottom: 20px;
        }
      }

      .form-control {
        display: block;
        width: 100%;
        background-color: $text-color-negativ;
        border-radius: 0;
        box-shadow: none;
        border: 0;
        color: black;
        padding: 15px 20px;
        text-indent: 1px;
        text-overflow: '';
        padding-right: 30px;
        height: 60px;
      }

      .checkbox {
        margin-top: 15px;
      }

      .overview {
        margin-bottom: 50px;
      }

      .btn {
        &.btn-link {
          margin-top: 20px;
          border-radius: 0;
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 15px;
          padding-bottom: 15px;
          text-transform: uppercase;
          letter-spacing: 1px;
          background-color: $text-color-negativ;
          color: black;

          &:before {
            content: "\f104";
            color: black;
            display: inline-block;
            font-family: "Font Awesome 5 Free";
            margin-right: 6px;
          }

          &:hover {
            background-color: darken($text-color-negativ, 10%);
          }
        }
      }

      .btn {
        &.btn-primary {
          margin-top: 20px;
          border-radius: 0;
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 15px;
          padding-bottom: 15px;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: white;

          &:before {
            content: "\f105";
            color: black;
            display: inline-block;
            font-family: "Font Awesome 5 Free";
            margin-right: 6px;
          }
        }
      }
    }
  }
}

select.selectpicker#country {
  display: block!important;
}

.help-block {
  background-color: $primary;
  display: block;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  position: relative;
  font-size: $font-size-sm * 0.9;

  &:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent $primary transparent;
    top:-7px;
    left: 50%;
    margin-left: -7px;
    content:"";
    position: absolute;
  }
}