.TeaserSlider {
  position: relative;

  .TeaserSliderPane {
    .container-wrapper {
      background-size: cover;
      background-position: center;

      .overlay {
        height: 100vh;
        background-color: rgba(0,0,0,0.7);
        display: flex;
        align-items: center;

        @include media-breakpoint-down('sm') {
          height: 70vh;
          padding-right: 30px;
        }

        h3 {
          color: white;
          font-size: $h1-font-size;
          text-transform: uppercase;
          font-weight: 300;
          margin-bottom: 30px;

          @include media-breakpoint-down(sm) {
            font-size: $h3-font-size;
          }

          strong {
            font-weight: 600;
          }
        }

        p {
          color: $text-color-negativ;

          .button {
            display: inline-block;
            margin-top: 40px;
          }
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    right: 15px;
    z-index: 100;
    height: 100vh;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down('sm') {
      height: 70vh;
    }

    li {
      display: block;
      text-align: center;

      button {
        width: 12px;
        height: 12px;
        background-color: white;
        border: 0;
        text-indent: -9999px;
        overflow: hidden;
        border-radius: 50%;
        display: block;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
      }

      &.slick-active {
        button {
          background-color: transparent;
          border: 3px solid $primary;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}