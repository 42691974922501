.chart-element {
  margin-top: 50px;
  margin-left: -15px !important;
  margin-right: -15px !important;
  display: block;

  .row {
    display: flex !important;
  }

  .chart {
    .chart-top {
      border: 2px solid $primary;
      display: flex;
      justify-content: center;
      align-items: center;

      background-image: linear-gradient(45deg, $primary 25%, #000000 25%, #000000 50%, $primary 50%, $primary 75%, #000000 75%, #000000 100%);
      background-size: 5.66px 5.66px;

      .content {
        span {
          background-color: black;
          display: inline-block;
          padding: 2px 7px;
          color: white;
          font-weight: 600;
          font-size: $font-size-lg;

          @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
          }
        }
      }
    }

    .chart-top-swapped {
      border-left: 2px solid white;
      border-top: 2px solid white;
      border-right: 2px solid white;
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        text-align: center;
        span {
          display: inline-block;
          color: white;
          font-weight: 600;
          font-size: $font-size-lg;

          @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
          }

          &.small {
            font-size: $font-size-sm;
            font-weight: 300;
          }
        }
      }
    }

    .chart-bottom {
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        text-align: center;
        span {
          display: block;
          color: white;
          font-weight: 600;
          font-size: $font-size-lg;

          &.small {
            font-size: $font-size-sm;
            font-weight: 300;
          }
        }
      }
    }

    .chart-bottom-swapped {
      border: 2px solid $primary;
      display: flex;
      justify-content: center;
      align-items: center;

      background-image: linear-gradient(45deg, $primary 25%, #000000 25%, #000000 50%, $primary 50%, $primary 75%, #000000 75%, #000000 100%);
      background-size: 5.66px 5.66px;

      .content {
        span {
          background-color: black;
          display: inline-block;
          padding: 2px 7px;
          color: white;
          font-weight: 600;
          font-size: $font-size-lg;
        }
      }
    }

    &.chart-1 {
      .chart-top {
        height: 45px;
      }

      .chart-bottom {
        height: 300px;
      }
    }

    &.chart-2 {
      .chart-top {
        height: 75px;
      }

      .chart-bottom {
        height: 360px;
      }
    }

    &.chart-3 {
      .chart-top-swapped {
        height: 41px;
      }

      .chart-bottom-swapped {
        height: 288px;
      }
    }
  }
}

.car-info {
  p {
    color: darkgray;
    margin-top: 15px;
    font-size: $font-size-sm;
  }
}