.icon-phone {
  &:before {
    content: "\f095";
    color: $primary;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    margin-right: 10px;
  }
}

.icon-marker {
  &:before {
    content: "\f041";
    color: $primary;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    margin-right: 10px;
  }
}

.icon-angle {
  &:before {
    content: "\f0da";
    color: $primary;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    margin-right: 5px;
  }
}