.ContactBox {
  padding-top: 30px;
  padding-bottom: 60px;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  &.wrapper {
    @include media-breakpoint-down(sm) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  h2 {
    text-transform: uppercase;
    font-weight: 300;

    &:after {
      width: 140px;
      height: 6px;
      display: block;
      background-color: $primary;
      content:"";
      margin-top: 14px;
      margin-bottom: 30px;
    }
  }

  .row {
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.Company {
  min-height: 100%;
  background-color: rgba(0,0,0,0.05);
  padding: 20px;
  position: relative;

  .note-info {
    position: absolute;
    top: -15px;
    right: -15px;

    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: $font-size-sm * 0.8;

      &:before {
        content:"\f041";
        font-family: "Font Awesome 5 Free";
        color: white;
        display: block;
        text-align: center;
        font-size: $font-size-base;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 5px;
  }
}