$image-margin: 15px !default;
$image-breakpoint: 768px !default;

.img {
  margin-bottom: $image-margin;
}

.img-center,
.img-bottomcenter {
  text-align: center;
}

.img-right,
.img-bottomright {
  text-align: right;
}

@media (min-width: $image-breakpoint) {
  .img-float-left {
	float: left;
	margin-right: $image-margin;
  }

  .img-float-right {
	float: right;
	margin-left: $image-margin;
  }
}