.Accordion {
  &.accordion-contact {
    margin-top: 15px;

    .AccordionElement.card {
      border-radius: 0;
      border: 0;

      .card-header {
        padding: 0;
        background-color: transparent;
        border-bottom: 0;
        margin-bottom: 0;

        h3.card-title {
          margin: 0;

          &:after {
            display: none;
          }

          button {
            &.btn {
              &.btn-link {
                @extend .btn-primary;
                border-radius: 0;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 15px;
                padding-bottom: 15px;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: white;
                font-weight: 200;
                display: inline-block;
                width: auto;
                box-shadow: none !important;

                &:before {
                  content: "\f105";
                  color: black;
                  display: inline-block;
                  font-family: "Font Awesome 5 Free";
                  margin-right: 0;
                }

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
      .card-header {
        & + div {
          .card-body {
            padding: 0;
            a {
              color: $primary;
            }
          }
        }
      }
    }
  }
}