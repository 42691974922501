$light : transparent;

$font-family-base: 'Open Sans';

$orange: #FF8900;
$primary: $orange;

$dark-grey: #242424;

$text-color-negativ: #DDDBDB;

$color-whatsapp: #25d366;
$color-twitter: #1da1f2;
$color-facebook: #3b5998;
$color-facebook: #3b5998;

$modal-content-color: #fff;
$modal-content-bg: #000;
$modal-backdrop-opacity: .7;
$modal-footer-border-color: $primary;