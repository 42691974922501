.CarConfigurator {
  &.wrapper {
    background-color: black;
    padding-top: 30px;

    @include media-breakpoint-down('sm') {
      padding-bottom: 30px;
    }

    h3, .h3 {
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      position: relative;
      padding-left: 38px;
      margin-bottom: 20px;

      @include media-breakpoint-down(sm) {
        font-size: $h4-font-size;
      }

      &:before {
        content:"";
        background-image: url("../img/streifen.png");
        background-size: contain;
        width: 32px;
        height: 28px;
        position: absolute;
        left: 0;
        background-repeat: no-repeat;
        top: 4px;
      }
    }

    button {
      width: 100%;
      height: 54px;
      border-radius: 0;
      box-shadow: none;
      border: 0;
      background-color: $primary;

      @include media-breakpoint-up(md) {
        width: 54px;
        display: block;
      }

      .fa {
        color: white;
        font-size: $font-size-lg;

        @include media-breakpoint-up(md) {
          font-size: $font-size-lg * 1.4;
        }
      }

      .mobile-only {
        display: inline-block;
        color: white;
        font-size: $font-size-lg;
        padding-left: 5px;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    label {
      display: none;
    }

    p.select-wrapper {
      position: relative;

      &:after {
        content: "\f107";
        color: $primary;
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        margin-right: 6px;
        position: absolute;
        font-size: $font-size-lg * 1.2;
        right: 10px;
        top: 10px;
        pointer-events: none;
      }

      &.disabled {
        &:after {
          opacity: 0.2;
        }
      }

      select {
        display: block;
        width: 100%;
        background-color: #222222;
        border-radius: 0;
        box-shadow: none;
        border: 0;
        color: white;
        padding: 15px 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        padding-right: 30px;

        &[disabled="disabled"] {
          opacity: 0.2;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }
  }
}

.Engine {
  h2, .h2 {
    background-color: $primary;
    color: white;
    padding: 10px 20px;
    display: inline-block;
    font-weight: 400;
  }

  h3, .h3 {
    font-weight: 700;
    margin-top: 15px;
  }

  .price {
    font-size: $h2-font-size;
    margin-top: 20px;

    strong {
      font-size: $h1-font-size * 1.5;
      color: $primary;
    }

    .tax {
      font-size: $font-size-sm;
    }

    .pdf-download {
      display: block;
      color: black;
      font-size: $font-size-sm;
      font-weight: normal;
    }
  }

  .pay-less {
    margin-bottom: 20px;
    position: relative;
    padding-left: 30px;
    border-top: 1px solid rgba(0,0,0,0.1);
    padding-top: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 15px;

    .price-less {
      color: $primary;
      font-size: $font-size-lg;
      font-weight: 700;
    }

    &:before {
      content:"\f05a";
      font-family: "Font Awesome 5 Free";
      position: absolute;
      left: 0;
      font-size: $font-size-lg;
    }
  }

  .steigerung {
    background-color: black;
    padding: 40px;
    margin-top: 30px;

    h4 {
      color: $primary;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .row {
      &:nth-of-type(odd) {
        background-color: rgba(255,255,255,0.05);
      }

      .col {
        padding: 10px 20px;
        color: white;
      }
    }
  }
}

.engine-voucher {
  background-color: $text-color-negativ;
  padding: 30px;
  margin-top: 20px;
  margin-bottom: 20px;

  .product-group {
    margin-bottom: 0;
  }

  .intro {
    padding-top: 0;
    padding-bottom: 30px;

    h3 {
      text-transform: uppercase;
      font-weight: 300;
      margin-top: 50px;

      &:after {
        width: 140px;
        height: 6px;
        display: block;
        background-color: $primary;
        content:"";
        margin-top: 14px;
        margin-bottom: 20px;
      }
    }
  }
}