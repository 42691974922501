.voucher {
  background-color: $primary;
  padding: 20px;
  text-align: center;
  margin-bottom: 15px;

  h3, .h3 {
    font-size: $h1-font-size;
    font-weight: 700;
    color: white;
    letter-spacing: 2px;
    margin-bottom: 20px;

    @include media-breakpoint-down('md') {
      font-size: $h3-font-size;
    }
  }

  p {
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }
}

.product-group {
  margin-bottom: 50px;
}

.success-message {
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  z-index: 3000;
  text-align: center;

  .alert-success {
    background-color: $primary;
    border: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;

    a {
      color: white;
      border-bottom: 2px dotted;
    }

    span.close-success-message {
      margin-left: 20px;
      @extend .btn;
      @extend .btn-light;
      @extend .btn-sm;
      padding-left: 15px;
      padding-right: 15px;
      border: 0;
      box-shadow: none;
      background-color: white;
      color: black;
      cursor: pointer;

      &:hover {
        color: white;
        background-color: black;
      }
    }

    a.cart-link-success-message {
      margin-left: 20px;
      @extend .btn;
      @extend .btn-light;
      @extend .btn-sm;
      padding-left: 15px;
      padding-right: 15px;
      border: 0;
      box-shadow: none;
      background-color: black;
      color: white;
      cursor: pointer;

      &:hover {
        color: black;
        background-color: white;
      }
    }
  }
}

.ProductsBox {
  .element_group {
    display: none;
  }
}